.choose-company-section-v2 {
  & { display: flex; justify-content: space-between; height: 100%; }

  .seperator {
    & { padding: 50px 0; display: -webkit-box; display: flex; -webkit-box-pack: center; justify-content: center; -webkit-box-align: center; align-items: center;  position: relative; -webkit-box-flex: 1; flex: 1 0 auto; }
    .line { position: absolute;top: 50px; bottom: 50px; width: 1px; background: #8c93a5;}
    .text { background: #f2f3f7; z-index: 9; position: relative; padding: 15px 0;  color: #1c2238; font-size: 26px; text-transform: uppercase; font-weight: 900; }
  }

  .add-coupons-right {
    & { display: flex; -webkit-box-align: center; align-items: center; padding: 50px; -webkit-box-flex: 1; flex: 1 0 auto;}
    .add-card {
      & { box-shadow: 0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12); border-radius: 6px; }
      .add-a-company {
        & { background-color: #fff; text-align: center; display: flex; flex-direction: column; align-items: center; padding: 70px 20px; border-radius: 6px; }

        img { width: 135px; }
        .text { color: #a3a3a3; padding: 5px 10px; margin-bottom: 20px; }
      }
    }
  }

  .search-company-card {
    & { display: flex; flex-direction: column; color: black; flex: 1 0 1; padding: 50px 10px; gap: 2rem;height: calc(100% - 60px); }
    .companies-item-div-container {
      & { padding: 0px 50px; display: flex; flex: 1; flex-direction: column; gap: 1rem; overflow: hidden; overflow-y: scroll; }
      .companies-item-div {
        & { display: flex; gap: 1rem; align-items: center; cursor: pointer; }
        span { font-weight: bold; }
      }
    }
    .confirm-div { width: 100%; display: flex; justify-content: center; align-items: center; }
  }

  .selectedCompany-div {
    & { padding: 12px 16px; }
    .selected-company-item {
      & { position: relative; height: 100vh; display: flex; align-items: center; margin: 4px 0; background-color: #1c2238; width: fit-content; padding: .5rem; color: white; border-radius: 8px; }
    }
  }

  .loading-container {
    position: absolute; /* Makes it independent of its parent's layout */
    top: 50%; /* Moves to the middle vertically */
    left: 50%; /* Moves to the middle horizontally */
    transform: translate(-50%, -50%); /* Adjusts the spinner to be perfectly centered */
}


}