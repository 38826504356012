
.categories-container {
  & { padding: 1rem; height: 100%; width: 100%; }

  .loading { display: flex; justify-content: center; align-items: center; height: 400px; }

  .header-section{
    &{background-color: white; border-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; display: flex; width: 100%; align-items: center; justify-content: space-between; padding: 1rem 2rem;}
    .title{  margin: 10px 0;  font-size: 1.5em; font-weight: 800; text-transform: uppercase;  color: #535c68; font-family: 'proxima-nova', sans-serif; }
    .header-items-div{ display: flex; align-items: center; gap: .5rem;}
    .filter-button{font-weight: 800; font-family: 'proxima-nova', sans-serif; font-size: 14px; text-transform: uppercase;}
  }

  .status-active {
    & { position: relative; padding: .25rem 1.25rem; display: flex; width: fit-content; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #dff0de; color: #329c3a; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #329c3a; }
  }
  .status-invite {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #fff2d7; color: #f9b104; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #f9b104; }
  }
  .status-pending {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center;width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #d4e7ff; color: #0472f9; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #0472f9; }
  }
  .status-suspended {
    & { position: relative;padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
  }

  .category-title { font-weight: bold; font-family: 'proxima-nova', sans-serif; }
  .subCategory-title { font-weight: normal; font-size: 14px; font-family: 'proxima-nova', sans-serif; }

  // .agent-table {
  //   & { height: 80%; }
  //   .company-table-body{
  //     & { }

  //     .company-table-row{
  //       &{} 
        
  //       .company-table-cell{
  //         &{ border-bottom: none !important;  font-weight: 400 !important; }
  //         .companyName{
  //           display: flex; align-items: center; gap: .5rem;
  //           img{ height: 32px; width: 32px; border-radius: 100%; object-fit: cover;}
  //         }
  //         // .icons-container{
  //         //   display: flex; width: fit-content; gap: 8px;
  //         //   .button-icon{width: 20px !important; padding: 0; margin: 0;}
  //         // }

  //         .couponDiv{
  //           &{border-radius: 5px;  border: 1px solid #e3e3e7;  position: relative;  width: 75px;  text-align: center;  padding: 4px 15px;  gap:4px;  padding-bottom: 0;  font-size: 14px; display: flex; align-items: center;}
  //           // img{ height: 16px; width: 20px;}
  //           .couponImg{width:13px;}
  //           .mapIcon{ width: 10px;}
  //         }
  //       }
  //     }
  //   }
  // }
  // .agent-table-pagination {
  //   & { height: 10%; }
  // }
}


// .company-table-head { background-color: #f3f4fb; }
// .company-head-cell { font-size: 13px;  color: #9294a3 !important; font-family: 'proxima-nova', sans-serif; text-transform: capitalize !important; font-weight: 600 !important; }

.grey-btn{ background: #f1f2f6 !important;  color: #535c68 !important;    margin: 0 !important;  min-width: 64px !important;  line-height: 36px !important;  padding: 0 16px !important;  border-radius: 4px !important; overflow: visible !important;}

.blue-button{ font-family: 'proxima-nova', sans-serif;  color: #fff; background-color: #2b1de8 !important; text-transform: capitalize !important; border-radius: 6px !important; padding: 8px 34px !important; height: 38px !important; }
#panel1-header > .css-o4b71y-MuiAccordionSummary-content{display: flex !important; align-items: center !important; gap: 1rem;}


#panel1-header-items > .css-o4b71y-MuiAccordionSummary-content{display: flex !important; align-items: center !important; gap: 0.5rem;}