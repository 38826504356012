
.agents-container {
  & { padding: 1rem; height: 100%; width: 100%; }

  .header-section{
    &{background-color: white; border-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; display: flex; width: 100%; align-items: center; justify-content: space-between; padding: 1rem 2rem;}
    .title{  margin: 10px 0;  font-size: 1.5em; font-weight: 800; text-transform: uppercase;  color: #535c68; font-family: 'proxima-nova', sans-serif; }
    .header-items-div{ display: flex; align-items: center; gap: .5rem;}
    .filter-button{font-weight: 800; font-family: 'proxima-nova', sans-serif;; font-size: 14px; text-transform: uppercase;}
  }

  .agent-table-head { 
    & { background-color: #f3f4fb; }
    .agent-head-cell{ font-size: 13px; color: #9294a3 !important; font-family: 'proxima-nova', sans-serif; text-transform: uppercase !important; font-weight: 800 !important; }
  }
  .status { 
    & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; max-width: 120px; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); }
  }
  .status-active {
    & { background: #dff0de; color: #329c3a; }
    &::before { background: #329c3a; }
  }
  .status-invite {
    & { background: #fff2d7; color: #f9b104; }
    &::before { background: #f9b104; }
  }
  .status-pending {
    & { background: #d4e7ff; color: #0472f9; }
    &::before { background: #0472f9; }
  }
  .status-suspended {
    & { background: #ffced8; color: #dc143c; }
    &::before { background: #dc143c; }
  }

  .agent-table {
    & { height: 80%; }
  }
  .agent-table-pagination {
    & { height: 10%; }
  }
}