.blogs-page {
  & { height: 100%; width: 100%; }

  .template-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; border-bottom: 1px solid #bbb; }
  }
  .template-section {
    & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }

    //main grid/table
    .blogs {
      & { width: 100%; height: 100%; display: flex; align-items: flex-start; align-content: flex-start; flex-wrap: wrap; gap: 15px; overflow-y: scroll; }

      .blog-card { 
        & { padding: 16px; width: 305px; min-height: 435px; background: #FFFFFF; border-radius: 8px; position: relative; box-shadow: 0px 3px 10px #0000001a !important; }
        p {
          & { margin: 0 0 8px 0; padding: 0; }
        }
        .retailers-img {
          & { display: flex; align-items: center; justify-content: center; }
          img {
            & { width: 100%; }
          }
        }

        .post-status {
          & { height: 8px; margin-bottom: 8px; position: absolute; top: 0; left: 0; width: 100%; }
        }
      
        .draft { background-color: #C6C6C6; }
        .published { background-color: #1c2238; color: #FFF; }
        
        .blog-img-container {
          & { height: 168px; width: 100%; max-height: 168px; max-width: 100%; margin-bottom: 16px;}
          .devotional-img {border-radius: 4px; object-fit: cover; height: 100%; width: 100%; }
        }
        .placeholder-img {
          & { border-radius: 4px; padding: 10px 0; max-height: 168px; min-height: 168px;  border-radius: 10px; border: 2px dashed #656565; display: flex; flex-direction: column; align-items: center; justify-content: center; cursor: pointer; }
          img { border-radius: 4px; object-fit: contain; height: 125px; max-width: 70%; width: 70%; }
          .placeholder-text { height: 30%; text-align: center; width: 90%; }
        }

        .blog-title {
          & { margin-top: 10px; min-width: 100%; padding-top: 10px; }
        }

        .retailers-row{ margin-top: 10px; display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px; margin-top: 10px; 
          p {
            & { color:#000000; font-weight: bold; font-size: 16px; width: 100%; }
          }
          button{
            & { width: 100%; }
          }
        }
        .engaments-row { 
          & { margin-top: 10px; display: flex; justify-content: space-between; }
        }

        .editable-row{
          & { margin-top: 10px; font-size: 14px; display: flex; justify-content: space-between; }
          .status-row {
            & { align-items: center; display: flex; gap: 10px; }
              .author-tags {
              .author-tag { 
                & { cursor: pointer; padding: 4px 10px; border-radius: 24px;}
              }
            }
          }
          .edit-btns {
            & { gap: 10px; }
            .edit-btn {
              & { cursor: pointer; }
            }
          }
        }

        .blog-title-text {  font-weight: 600;font-weight: 600; }
        .blog-title-date {
          & { font-size: 14px; font-weight: bold; }
            span { font-weight: normal;}
        } 

        .tags-row{ 
          & { margin: 16px 0px 16px 0px; display: flex; flex-wrap: wrap; gap: 15px; }
          .number-icon{ 
            & { font-size: 10px; background-color: #1c2238; color: white; border-radius: 50%; display: flex; padding: 1px 5px; align-items: center; justify-content: center;  width: 20px; height: 20px; position: absolute; right: -12px; top: -12px; }
          }
          .author-tag {
            & { position: relative; font-size: 14px;color: #000000; border-radius: 5px; height: 24px; background-color: transparent; padding: 8px;  display: flex; align-items: center; justify-content: center; gap: 5px; font-weight: 600; cursor: pointer; }
            .remove-author { height: 16px; width: 16px; cursor: pointer; }
            .add-author { height: 18px; width: 18px; cursor: pointer; }
          }
          .tag {
            & { position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; cursor: pointer; }
            .remove-tag { height: 16px; width: 16px; cursor: pointer; }
            .add-tag { height: 18px; width: 18px; cursor: pointer; }
          }
        }
      }
    }
  }
}