@use '../../components/CreateCouponV2/ChooseCompany/ChooseCompany.scss';
@use '../../components/CreateCouponV2/HeaderStepper/HeaderStepper.scss';
@use '../../components/CreateCouponV2/CreateCoupon/CreateCouponSection.scss';

.create-coupon-container-v2 {
  & { padding: 1rem; max-height: 100%; width: 100%; overflow: hidden; overflow-y: auto; font-family: 'proxima-nova', sans-serif; }
  .footer-buttons {
    & { display: flex; align-items: center; justify-content: space-between; padding: 3rem; }
  }
  .vertical-container { 
    & { display: flex;  gap: 2rem; }
  }
  .vertical {
    & { display: flex; flex-direction: column !important; gap: 2rem; }
  }
  .coupon-error-text { width: 100%; color: red; }
}


// Common Classes

.flex-row-container {
  & { display: flex; justify-content: space-between; align-items: center; width: 100%; }
}

.logo-image {
  & { width: 60px; height: 60px; border-radius: 100%; object-fit: cover; }
}

.title {
  & { font-weight: 600; color: #1c2238; width: 100%; }
}

.subtitle {
  & { font-size: 13px; color: #1c2238; }
}

.full-width {
  width: 100%;
}

.checkbox-container {
  & { display: flex; align-items: center; gap: .25rem; width: 100%; }
}

.checkbox-span {
  & { font-size: 14px; display: flex; align-items: center; gap: 3px; color: #1c2238; }
}

.location-header {
  & { display: flex; justify-content: space-between; align-items: center; gap: 1rem; width: 100%; }
}

.title-subtitle-container {
  & { display: flex; flex-direction: column; gap: .25rem; }
}

.gray-title {
  & { font-size: 0.85em; color: #636e72; line-height: 20px;  }
}

.keyword-list {
  & { display: flex; flex-wrap: wrap; gap: .5rem; }
  .keywordBatch {
    & { background-color: #2b1de8;font-size: 14px;  color: #f2f3f7; display: flex; gap: 1rem; align-items: center; border-radius: 30px; padding: .25rem  .75rem; }
  }
}

.center-item {
  display: flex; flex-direction:  column; align-items: center;
}