.overview-container {
  & { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;  color: #000;  }

  .overview {
    & { height: 100%; width: 100%; overflow-y: scroll; display: flex; align-items: center; justify-content: center; padding: 25px 20px; }
    .highchart-container {
      & { width: 1336px; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: flex-start; gap: 25px; }
    .overview-details {
      & { height: 100px; width: 100%; display: flex; align-items: center; justify-content: space-between; gap: 40px; }
      .card {
        & { height: 85px; width: inherit; background: #fff; border-radius: 5px; box-shadow: 0 0 10px #bbb; display: flex; align-items: center; justify-content: space-between; padding: 0 15px; }
        .card-details {
          & { display: flex; align-items: flex-start; flex-direction: column;  }
          .card-number { font-size: 24px; padding-bottom: 5px; }
          .card-title { font-size: 14px;  }
        }
        .card-img {
          & { height: 50px; width: 50px; display: flex; align-items: center; justify-content: center; background: #e6e9ef; border-radius: 100%;  }
          img { height: 24px; width: 24px; }
        }
      }
    }

    .overview-charts { 
      & { width: 100%; display: flex; border-radius: 5px; }
      .chart-full-width {
        & { flex: 1; max-width: 100%; box-shadow: 0 0 10px #bbb; border-radius: 5px; }
      }
      .chart-half-width {
        & { width: calc(50% - 20px); box-shadow: 0 0 10px #bbb; border-radius: 5px; }
      }
      .chart-content {
        & { display: flex; justify-content: center; align-items: center; font-size: 1.7em; font-weight: 600;}
        & .chartContainer { width: 100%; border-radius: 5px; }
      }
    }
    .flex-gap { justify-content: space-between; }
    }
  }
}