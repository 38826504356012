
.resource-container {
  & { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; color: #000;  }
  .resource {
    & { width: 100%; height: 100%; padding: 0 25px; overflow-y: scroll; overflow-x: hidden; }
    .resource-header {
      & { width: 100%; height: 10%; display: flex; align-items: center; justify-content: space-between; }
    }
    .resource-page {
      & { width: 100%; height: 90%;  display: flex; flex-wrap: wrap; justify-content: space-between; padding-bottom: 25px; }

      .card {
        & { flex: 0 0 32%; border-radius: 5px; box-shadow: 2px 2px 10px #aaa; margin-bottom: 25px; }
        .card-header-text {
          & { padding: 15px 10px; }
        }
        .card-image {
          img { height: 200px; width: 100%; object-fit: cover; }
        }
        .card-content {
          & { padding: 5px 10px; }
        }
        .card-actions {
          & { padding: 15px 10px; display: flex; align-items: center; gap: 10px; }
          button { box-shadow: 2px 2px 5px #bbb; }
        }
      }

    }
  }
}