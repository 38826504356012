
.edit-emergency-services-drawer { position: fixed; display: flex; flex-direction: column; top: 0; right: 0; width: 480px; max-width: 100vw; height: 100dvh; max-height: 100dvh; overflow: hidden; z-index: 1000; background: white; box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
    .drawer-header { height: 60px; background: #535c68; color: white; display: flex; align-items: center; font-size: 18px; font-weight: bold; padding: 0 20px; flex-shrink: 0; }
    .drawer-content { flex: 1; overflow-y: auto; padding: 16px; min-height: calc(100dvh - 120px); background: white; }
    .form-table { width: 100%; border-collapse: collapse; margin-top: 10px; }
    .form-table th, .form-table td { border-bottom: 1px solid #e0e0e0; padding: 12px; text-align: left; }
    .form-table th { background: #f5f5f5; font-weight: 600; }
    .MuiTextField-root, .MuiAutocomplete-root { width: 100%; }
    .manage-location-buttons { display: flex; justify-content: space-between; align-items: center; padding: 10px 20px; background: white; height: 60px; flex-shrink: 0; }
    @media (max-width: 768px) { width: 100vw; .manage-location-buttons { flex-direction: column; gap: 10px; } .drawer-header { font-size: 16px; } .drawer-content { padding: 10px; } }
    @media (max-width: 480px) { width: 100vw; left: 0; right: unset; .manage-location-buttons { flex-direction: column; align-items: stretch; } .manage-location-buttons button { width: 100%; } }
  }
  .drawer-overlay { position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; background: rgba(0, 0, 0, 0.5); z-index: 999; }
  
  
  