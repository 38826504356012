$themeColor1: #00A4CC; 
$themeAccentColor1: #814495;
$buttonColor1: #eb4d4b;
$buttonAccentColor1: #ff7979;

// -------------------------- NavBar ----------------------------//
.navbar {
  & { background: #1c2238; color: #fff; display: flex; align-items: center; justify-content: space-between; height: 65px; }

  .company-logo {
    & { cursor: pointer; width: 65px; height: 65px; display: flex; align-items: center; justify-content: center; padding: 0 0 0 15px; background: #151929; }
    img { height: 50px; width: 50px; }
  }

  .user-nav {
    & { display: flex; align-items: center; justify-content: center; gap: 15px; padding: 0 15px; }
    .user-avatar {
      & { cursor: pointer; }
      & { height: 30px; width: 30px; background: #fff; border-radius: 5px; }
    }
    .user-name {
      & { display: flex; flex-direction: column; align-items: center; max-width: 150px; }
      .username { white-space: pre; overflow: hidden; text-overflow: ellipsis; width: 100%; }
      .user-email { font-size: 10px; opacity: 0.7;  white-space: pre; overflow: hidden; text-overflow: ellipsis; width: 100%; }
    }
    .nav-dropdown {
      & { cursor: pointer; height: 30px; width: 30px; }
    .nav-dropdown-icon { height: 30px; width: 30px; }

    }
  }
}


// ------------------- all - main - container --------------------//
.main-container {
  & { display: flex; height: 100%; background: #f2f3f7; color: #000; width: inherit;}
}

// -------------------------- NavBar ----------------------------//
.main {
  & { display: flex; height: 100%; margin: 0 auto; }
}


// -------------------------- SideBar ----------------------------//
.sidebar {
  & { background: #1c2238; color: #fff; width: 200px; min-width: 200px; height: 100%;  display: flex; flex-direction: column; align-items: center; justify-content: space-between; transition: width 200ms ease; }
  *::-webkit-scrollbar {display:none;}

  .item-icon { height: 20px; width: 20px; }

  .sidebar-content {
    & { width: 100%; overflow-y: scroll; margin-bottom: auto; }
  }

  .manage-heading {
    & { height: 40px; width: 100%; margin: 15px 0;  padding: 0 1rem; display: flex; align-items: center; justify-content: space-between; font-weight: 600; font-size: 20px; }
    .back-icon { cursor: pointer; height: 35px; width: 35px; }
  }

  .manage-container {
    & { width: 100%; padding-left: 4%; padding-top: 10px; }
  }

  .build-container {
    & { width: 100%; padding-left: 4%; margin-bottom: auto; }
    .build-heading {
      & { height: 40px; width: 100%;  margin: 10px 0; font-weight: 600; font-size: 20px; }
    }
    
    .new-coupon-btn {
      
      & {  position: relative;  width: 155px; cursor: pointer;   min-width: 30px !important; height: 35px; font-weight: 700; margin: 0 auto;  display: flex; align-items: center; justify-content: center; }
      
      .new-btn{
        & {z-index: 3; border-radius: 20px; display: flex; align-items: center;   width: 155px; background: #e84118; color: #fff;   min-width: 30px !important; height: 35px; font-weight: 700; margin: 0 auto;  display: flex; align-items: center; justify-content: center; }
      }
      
      .background-div{
        &{       z-index: 1; background-color: white; width: 200px; position: absolute; top: -5px; border-radius: 10px;  left: -20px; right: 0; bottom: 0; height: 45px;}
      }
    }
    
    .sidebar-closed-coupon-btn{
      &{border-radius: 100% !important; width: 35px !important; height: 35px !important; padding: 0 !important;}
    }
  }

  .admin-container {
    & { align-self: flex-end; width: 100%; padding-left: 4%; }
  }

  .user-info {
    & { width: 100%; background: #1c2238; color: #fff; display: flex; align-items: center; justify-content: space-between; }
  
    .company-logo {
      & { cursor: pointer; width: 65px; height: 65px; display: flex; align-items: center; justify-content: center; padding: 0 0 0 15px; background: #151929; }
      img { height: 50px; width: 50px; }
    }
  
    .user-nav {
      & { display: flex; align-items: center; justify-content: center; gap: 15px; padding: 0 5px; }
      .user-name {
        & { display: flex; flex-direction: column; align-items: center; max-width: 150px; }
        .username { font-size: 14px; white-space: pre; overflow: hidden; text-overflow: ellipsis; width: 100%; }
        .user-email { font-size: 10px; opacity: 0.7;  white-space: pre; overflow: hidden; text-overflow: ellipsis; width: 100%; }
      }
    }
  }
}

.new-coupon {
  & { cursor: pointer; height: 50px; display: flex; align-items: center; justify-content: center; position: relative; }
  .new-btn{
    & { cursor: pointer; z-index: 3; border-radius: 20px; display: flex; align-items: center; background: #e84118; color: #fff; height: 35px; padding: 0 1rem; font-weight: 700; margin: 0 auto;  display: flex; align-items: center; justify-content: center; }
  }
}

.new-coupon-active {
  & { background: #f2f3f7; color: #1c2238; border-top-left-radius: 5px; border-bottom-left-radius: 5px; }
  &:hover { background: #f2f3f7; }
  &::before {
    content: "";
    background: #f2f3f7;
    position: absolute;
    right: 0;
    top: -10px;
    height: 10px;
    width: 10px;
  }
  &::after {
    content: "";
    background: #f2f3f7;
    position: absolute;
    right: 0;
    bottom: -10px;
    height: 10px;
    width: 10px;
  }
  .corner-radius-helper-1 {
    & { display: block; z-index: 1; background: #1c2238; position: absolute; right: 0; bottom: -10px; height: 10px; width: 10px; border-top-right-radius: 5px;}
  }
  .corner-radius-helper-2 {
    & { display: block; z-index: 1; background: #1c2238; position: absolute; right: 0; top: -10px; height: 10px; width: 10px; border-bottom-right-radius: 5px;}
  }
}

.sidebar-item {
  & { cursor: pointer; position: relative; height: 35px; margin: 5px 0; display: flex; align-items: center; justify-content: flex-start; gap: 15px; padding-left: 20px; }
  &:hover { background: #15192980; }
  .corner-radius-helper-1 {
    & { display: none; }
  }
  .corner-radius-helper-2 {
    & { display: none; }
  }
}

.active-item {
  & { background: #f2f3f7; color: #1c2238; border-top-left-radius: 5px; border-bottom-left-radius: 5px; }
  &:hover { background: #f2f3f7; }
  &::before {
    content: "";
    background: #f2f3f7;
    position: absolute;
    right: 0;
    top: -10px;
    height: 10px;
    width: 10px;
  }
  &::after {
    content: "";
    background: #f2f3f7;
    position: absolute;
    right: 0;
    bottom: -10px;
    height: 10px;
    width: 10px;
  }
  .corner-radius-helper-1 {
    & { display: block; z-index: 1; background: #1c2238; position: absolute; right: 0; bottom: -10px; height: 10px; width: 10px; border-top-right-radius: 5px;}
  }
  .corner-radius-helper-2 {
    & { display: block; z-index: 1; background: #1c2238; position: absolute; right: 0; top: -10px; height: 10px; width: 10px; border-bottom-right-radius: 5px;}
  }
}

