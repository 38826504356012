/* You can add global styles to this file, and also import other style files */
@use '../Layout/Layout.scss';
@use '../Pages/Agents/Agents.scss';
@use '../Pages/Login/Login.scss';
@use '../Pages/Overview/Overview.scss';
@use '../Pages/Resources/Resources.scss';
@use '../Pages/Blogs/Blogs.scss';
@use '../Pages/Tags/Tags.scss';
@use '../Pages/Topics/Topics.scss';
@use '../Pages/Companies/Companies.scss';
@use '../Pages/ERServices/ERServices.scss';
@use '../Pages/Coupons/Coupons.scss';
@use '../Pages/Categories/Categories.scss';
@use '../Pages/Create-Coupons/Create-Coupons.scss';
@use '../styles/Common.scss';
@use '../Pages/CreateCouponV2/CreateCouponV2.scss';


@use './Drawer.scss';
@use './Modal.scss';

@import url("https://use.typekit.net/oly7wxy.css");



// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
$mainFont : 'proxima-nova', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,900&display=swap');
$roboto : 'Roboto', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');
$sofia : 'Sofia', cursive;

@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
$syne: 'Syne', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$courier : 'Courier Prime', monospace;

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,600;0,700;0,800;1,500;1,600;1,700&display=swap');
$garamond : 'EB Garamond', serif;



$themeColor1: #00A4CC; 
$themeAccentColor1: #814495;
$buttonColor1: #1c2238;
$buttonAccentColor1: #ff7979;


/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
  // outline: 1px solid #ff0000;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  background: #fff;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: $mainFont;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}


//this is the global container 
.app-container {
  & { height: 100vh; width: 100vw; }
}

.link {
  & { font-weight: 600; font-size: 16px; color: #111; text-decoration: none; border-bottom: 2px solid transparent; transition: border-bottom 250ms ease;}
  &:visited { color: #323232; }
  &:hover { border-bottom: 2px solid #323232; }
}

.df-center {
  & { display: flex; align-items: center; justify-content: center; }
}

.w-100 {
  & { width: 100%; }
}

.mtb-1rem {
  & { margin: 1rem 0; }
}

.mtb-10px {
  & { margin: 10px 0; }
}

.btn-primary {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: $buttonColor1; border: 2px solid transparent; color: #fff; box-shadow: 0 0 5px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
  &:disabled {  background: #afafaf !important; }
} 

.btn-primary-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; border: 2px solid $buttonColor1; color: $buttonColor1; box-shadow: 0 0 5px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-white {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; color: #000000; border: 2px solid transparent; box-shadow: 0 0 10px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-white-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; color: #000000; border: 2px solid #000000; box-shadow: 0 0 10px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-red-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; color: #FF0000; border: 2px solid #FF0000; box-shadow: 0 0 10px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-green-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; color: #00b500; border: 2px solid #00b500; box-shadow: 0 0 10px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-black {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #111; color: #fff; border: 2px solid transparent; box-shadow: 0 0 10px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { background: #1b1b1b; }
} 

.text-input {
  & { position: relative; height: 40px; }
  .form-label {
    & { text-transform: capitalize; margin-bottom: 0.3rem; font-weight: 600; }
  }
  .form-input {
    & { height: 40px; width: 100%; display: flex; align-items: center; justify-content: center; }
    input {
      & { font-weight: 500; flex: 1; margin-right: auto; border: none; border-radius: 5px; border: 1px solid #c6c6c6; height: 40px; padding-left: .5rem; padding-right: 45px; background: #ffffff; font-size: 15px; }
      &:hover {
        & { border: 1px solid #858585; }
      }
      &:focus { outline: none; border: 1px solid #5c5c5c; }
    }
    img {margin: 0 .5rem;}
    .show-password {
      & { position: absolute; right: 5px; cursor: pointer; margin-right: 10px; background: transparent; }
    }
    .show-completion {
      & { position: absolute; right: 5px; margin-right: 10px; background: transparent; }
    }
  }
  .form-error {
    & { color: #D63F15; font-size: 12px; font-weight: 600; }
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  
}

::-webkit-scrollbar-thumb {
  background-color: #5e5e5e;
  outline: 1px solid #ffffff;
  border-radius: 2px;
}

