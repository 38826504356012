@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;

.drawer-container {
  & { height: 100%; display: flex; flex-direction: column; }
}

.drawer {
  
  & { position: relative; width: calc(100vw - 300px); height: 100vh; font-family: 'proxima-nova', sans-serif; display: flex; flex-direction: column; }
  .tool-button { height: 24px; width: 24px; margin-right: 10px; cursor: pointer; color: #007C89; }
  .active { border: 1px solid blue; }
  .disable { border: none; }
 
  header {
    & {  padding: 0 1rem; background: #535c68; color: #fff; width: auto; max-width: 100%; height: 60px;  display: flex; align-items: center; }
    h1 {
      & { margin: 0; font-size: 24px; color: #fff; align-items: center; display: flex; font-family: 'proxima-nova', sans-serif; }
      .back-button { height: 24px; width: 24px; margin-right: 10px; }
    }
  }

  .MuiTabs-flexContainer { background: #ffffff; }

  section { 
    
    & { height: calc(100% - 60px); width: 100%; padding-top: 1rem; }

    .edit-blog-post { margin-top: 1rem; }
    .locations-textfield { 
      & { display: flex; align-items: center; gap:1rem; margin: 2rem 0 0 0 ; }
      .textfields-container { flex: 1; display: grid; gap: 1rem; grid-template-columns: repeat(2, 1fr); }
    }
    .manage-location-buttons {
      & { display: flex; align-items: center; justify-content: space-between; }    
      .location-add-button{
        &:enabled {background-color: #2b1de8; color: white; cursor: pointer; pointer-events: all;}
        & { white-space: nowrap; border: 2px solid #2b1de8; font-family: 'proxima-nova', sans-serif; text-transform: uppercase; display: inline-flex; align-items: center; justify-content: center; position: relative; background-color: #2b1de8; outline: 0; border: 2px solid #2b1de8; margin: 0; border-radius: 0; cursor: pointer; user-select: none; vertical-align: middle; text-decoration: none; color: inherit; font-weight: 600; padding: 8px; height: 40px; border-radius: 4px; transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; color: #ffffff; }
        &:hover { text-decoration: none; background-color: #3629f2; border: 2px solid #2b1de8; }
        &:active { text-decoration: none; background-color: #2418cd; color: #ffffff; border: 2px solid #2b1de8; }
        &:disabled { text-decoration: none;  border: none; background-color: #d2d2d27a; color: #979797; cursor: default; pointer-events: all; }
      }
      .reset-btn{
        & {text-align: center; border: 0px solid #dcdfe6; background: #f4f4f5; color: #3b3e45; font-weight: 800; text-transform: uppercase; border-radius: 4px; padding: 8px 16px; cursor: pointer;font-family: proxima-nova;}
        &:hover { background-color: #e0e2e6; text-decoration: none; border: 0px solid #dcdfe6; color: #535c68;}
        &:active { background-color: #d3d5d9; border: 0px;}
        &:disabled { border: 0px solid #e5e7eb; background-color: #f4f4f5; color: #9ca3af; cursor: default; pointer-events: none; }
      }
    }

    .map-container{height: 500px}

    .editor-div {
      & { margin: 0 auto; margin-bottom: 40px; width: 80vw; max-width: 1076px; background-color: #FFFFFF; padding: 40px; display: flex; flex-wrap: wrap; border-radius: 8px; }
      span { font-size: 12px; color:#9A9A9A; }
      .title-header {
        & { width: 65%; padding-right: 40px; }
        .edit-blog-post {
          & { font-size: 14px; font-weight: 600; border: 1px solid #ccc; border-bottom: none; padding: .5rem .5rem; background: #e3e8f0; }
        }
        .quill { margin-bottom: 1rem; }
        .blog-snippet-quill .ql-toolbar.ql-snow + .ql-container.ql-snow { height: 150px; }
        .blog-snippet-quill .ql-toolbar.ql-snow { display: none; }
      }
      .content-container { 
        & { display: flex; gap: 40px; } 
        .text-editor-container { 
          & { width: 65%; } 
          .image-container { 
            & { margin-bottom: 24px; position: relative; display: flex; justify-content: center; align-items: center; } 
            .post-image { 
              & { width: 100%; max-width: 100%; max-height: 360px; border-radius: 10px; margin: 0; padding: 0; } 
            }
            .upload-image-buttons { 
              & { position: absolute; display: flex; flex-direction: column; gap: 20px; }
              .upload-button { 
                & { background-color: #1c2238; color: white; } 
              }
              .remove-button { 
                & { background-color: transparent; border: 2px solid #1c2238; }
                .buttons { 
                  & { color: #1c2238; } 
                }
              }
              .buttons { 
                & { color: white; } 
              }
            }
          }
          
          
          
        }
        .myeditor{
          
          .ql-snow{ border: none !important; 
          .ql-editor{
            padding: 0 !important; margin: 0 !important;
          }
        }
          
        }
      }

      .second-column{
        .trending-text{  font-size: 16px;  margin: 0;  padding: 0;  margin-bottom: 10px;  color:#9B9B9B;
        }
        .trending-items{  margin-bottom: 16px;  display: flex;  align-items: flex-start;  gap:16px;
          p{  margin: 0;  padding: 0;  font-weight: bold;  font-size: 14px;  color: #9B9B9B;
          } 
          .trending-image{
             height: 104px;
             border-radius: 10px;
          }
        }  
      }   
    }

    .MuiOutlinedInput-input { font-family: 'proxima-nova', sans-serif; }
    .MuiInputLabel-root { font-family: 'proxima-nova', sans-serif; }

  }

  footer {
    & { display: flex; align-items: center; justify-content: space-between; background-color: white; position: fixed; bottom: 0; height: 60px; padding: 0 20px; width: calc(100% - 300px); -webkit-box-shadow: 0px -10px 19px -14px rgba(0,0,0,0.31); -moz-box-shadow: 0px -10px 19px -14px rgba(0,0,0,0.31); box-shadow: 0px -10px 19px -14px rgba(0,0,0,0.31);}
  }
 
  .blog-edit-section { 
    & { position: relative; width: 80%;   overflow-x: hidden; background-color: #FFFFFF; display: flex; overflow-y: hidden; border-radius: 8px; margin-bottom: 30px;}
    
    .author-row { & { display: flex; gap: 10px; align-items: center; margin-bottom: 16px; } 
      .author-container { & { display: flex; justify-content: center; align-items: center; margin-right: 16px; } }
      img { & { display: flex; width: 32px !important; height: 32px; margin-right: 5px; background: transparent 0% 0% no-repeat padding-box; border-radius: 50%; opacity: 1; } }
      .author-tag { & { position: relative; font-size: 14px; color: #9B9B9B; background: #E9EAEE 0% 0% no-repeat padding-box; padding: 8px; border-radius: 4px; display: flex; align-items: center; justify-content: center; gap: 5px; width: 80px; height: 32px; cursor: pointer; } 
        .remove-author { & { height: 16px; width: 16px; cursor: pointer; } }
        .add-author { & { height: 18px; width: 18px; cursor: pointer; } }
      }
    }
  

    .blog-flower-1 {
      & { top: 300px; left: -260px; position: absolute; width: 624px; height: 629px;}
    }
    .blog-flower-2 {
      & { top: -200px; right: -200px; position: absolute; width: 624px; height: 629px; }
    }

    .edit-blog-post {
      margin-top: 1rem;
    }

    .editor-div{  width:100%;  background-color: #FFFFFF;   display: flex;  flex-wrap: wrap;  border-radius: 8px;

      .blog-author { & { font-size: 14px; color: #5c5c5c; } }
      .remove-blog-author { & { font-size: 14px; color: #5c5c5c; margin-top: 7px; } }


      .title-header { & { width: 100%; padding-right: 40px; } 
        .author-row { & { display: flex; gap: 10px; align-items: center; margin-bottom: 16px; } 
          .author-image { & { height: 32px; width: 32px; border-radius: 50%; object-fit: cover; background-color: #000000; } }
          .author-tag { & { position: relative; font-size: 14px; color: #9B9B9B; height: 24px; background-color: #E9EAEE; padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
            .remove-author { & { height: 16px; width: 16px; cursor: pointer; } }
            .add-author { & { height: 18px; width: 18px; cursor: pointer; } }
          }
        }
        .edit-blog-post { & { font-size: 14px; font-weight: 600; border: 1px solid #ccc; border-bottom: none; padding: .5rem .5rem; background: #e3e8f0; } }
        .quill { & { margin-bottom: 1rem; } }
        .blog-snippet-quill .ql-toolbar.ql-snow + .ql-container.ql-snow { & { height: 150px; } }
        .blog-snippet-quill .ql-toolbar.ql-snow { & { display: none; } }
      }

      .content-container {
        & { display: flex; width: 100%; gap:40px; }
        

        .text-editor-container{
          & { width: 100%; }
          .image-container { & { margin-bottom: 24px; position: relative; display: flex; justify-content: center; align-items: center; background: #aaa; border-radius: 5px; width: 689px; height: 455px; } 
            img { & { width: 100%; height: 100%; border-radius: 10px; margin: 0; padding: 0; object-fit: cover; } }
            iframe { & { width: 580px; max-width: 580px; max-height: 360px; height: 300px; } }
            .placeholder { & { height: 200px; width: 200px; } }
            .upload-image-buttons { & { display: none; align-items: center; justify-content: center; gap: 1rem; position: absolute; bottom: 10px; margin-bottom: 24px; }
              .upload-button { & { height: 30px; background-color: #1c2238; color: white; font-size: 12px; } }
              .remove-button { & { height: 30px; background-color: transparent; border: 2px solid #ffffff; color: #FFF; }
                .buttons { & { color: #ffffff; } } }
              .buttons { & { color: white; } }
            }
            .image-toggle-button { & { position: absolute; inset: auto 5px 5px auto; background: #ffffff87; padding: 0 1rem; border-radius: 5px; color: #000000; } }
            .upload-video-input { & { position: absolute; width: 300px; height: 30px; inset: auto auto 10px 5px; background: #272727; border-radius: 5px; color: #FFF; }
              input { & { background: #272727; border: none; color: #FFF; width: 100%; height: 30px; border-radius: 5px; padding: 0 5px; } 
              input:focus { & { outline: none; } } }
            }
          }

          
          
        }
        .myeditor{
          & { min-height: 300px; width: 100%; }
          .ql-snow{
            & { min-height: 300px; border: none !important; width: 100%; } 
          .ql-editor{
            & { min-height: 300px; padding: 0 !important; margin: 0 !important; }
          }
        }
          
        }
        

      }

      .image-and-title-container {
        & {  width: 100%; height: auto; }
        .image-container {
          & { float: left; width: 40%; height: 575px; background: #000; background: transparent 0% 0% no-repeat padding-box; }
        
          .image-change-btn {
            & { display: flex; position: absolute; z-index: 10; width: inherit; justify-content: flex-start; padding: 10px; color: #0A71F5; }
            &:hover { color: #3589f8; }
            &:active { color: white; }
            .landscape-btn { cursor: pointer; }
            .portrait-btn { cursor: pointer; }
          }
        
          .img {
            & { height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; position: relative; }
            & > img { height: 100%; width: 100%; }
            
            .upload-image-buttons {
              & { display: none; align-items: center; justify-content: center; gap: 1rem; position: absolute; bottom: 10px; margin-bottom: 24px; }
              .btn-primary { background: #0A71F5 0% 0% no-repeat padding-box; border-radius: 4px; opacity: 1;
                &:active { background-color: #085dcc; }
              }
              .btn-primary-border { border: 2px solid #0A71F5; border-radius: 4px; opacity: 1; background: transparent; color: #0A71F5;
                &:active { background-color: #085dcc; color: white; }
              }
            }
        
            &:hover {
              .upload-image-buttons {
                display: flex;
              }
            }
          }
        }
        

        .title-and-author-container {
          & { top: 100px; left: -100px; display: flex; align-items: center; justify-content: center; padding:0px 32px; width: 75%; min-height: 100px; width: 60%;}
          .title-and-author {
            & { position: relative; min-height: 100%; width: 100%;display: flex;flex-direction: column; }
            .title-container {
              & { width: 100%; margin-bottom: 24px;}
              .title-editor {
                & { resize: none; width: 100%; border: none; text-align: left; font: normal normal bold 34px/45px Lato; letter-spacing: 0px; color: #000000; }
                &:focus { outline: none; }
              }              
            }

            .blog-category {
              & { font-size: 14px; font-weight: 600; text-transform: uppercase; color: #B1B4A8; position: absolute; top: -20px; left: 0; display: flex; align-items: center; }
              hr { min-width: 60px; margin-right: 10px; }
            }

            .blog-author-and-date {
              & { display: flex; gap: 8px; text-align: left; font: normal normal 500 20px/27px Lato; letter-spacing: 0px; color: #9A9A9A; margin-top: 32px; margin-bottom: 16px; }
              input.date-editor {
                & { font-size: 20px; color: #9A9A9A; padding: 5px; border: none; outline: none; background-color: transparent; flex-grow: 1; }
                &:focus { border: none; outline: none; }
                &::placeholder { color: #9A9A9A; }
              }
            }            
          }
        }
      }

      .second-column {
        .trending-text { font-size: 16px; margin: 0; padding: 0; margin-bottom: 10px; color: #9B9B9B; }
        .trending-items { margin-bottom: 16px; display: flex; align-items: flex-start; gap: 16px;
          p { margin: 0; padding: 0; font-weight: bold; font-size: 14px; color: #9B9B9B; }
          .trending-image { height: 104px; border-radius: 10px; }
        }
      }
    }
   
  }



  .double-column-form { 
    & { display: flex; align-items: center; gap:1rem ;margin-top:auto;justify-content: space-between;   border-top: 1px solid #ddd; padding-top: 20px;}
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

}

.order-drawer {

  .order-drawer-address {
    & { background-color: #FFFFFF; box-shadow: 0px 2px 10px #0000000F; border-radius: 8px; margin-bottom: 10px; padding: 10px; margin: 0 20px 20px 20px; }
    .order-drawer-header {
      & {display: flex; justify-content: space-between; border-bottom: 1px solid #E3E3E3; }
      .header-section-1 { display: flex; justify-content: space-around; margin-left: 15px; }
      
      .header-section-2 { display: flex; justify-content: space-around; align-items: center; padding-right: 10px; }
      .date-input { height: 32px; border-radius: 4px; border: 1px solid #C6C6C6; font-size: 16px; font-weight: 600; margin-right: 15px; width: 256px; padding-inline: 5px; }
      .save-date-button { background-color: #AA7B59; border-radius: 4px; height: 32px; font-size: 16px; font-weight: 600; color: #ffffff; text-transform: capitalize; }
    }
  }

  // Order Details Drawer 
  .order-details { 
    & { position: relative; padding: 15px 30px; font-size: 14px; overflow-y: scroll; }
    h1 { font-family: $mulish; font-size: 18px; }
    .address-section { 
      & { display: flex; justify-content: space-between; font-size: 14px; }
      .customer-info { }
      .shop-info { & { margin-right: 15px; }
        .shop-name { font-size: 16px; font-weight: 700; font-family: $mulish; }
        p {font-weight: 600; font-size: 16px; }
      }
    }
    
    .black-btn { font-family: $mulish; background-color: #313131; color: #fff; padding: 2px 10px; font-size: 13px; }
    .products-table-container { & { margin: 30px auto 0; }
      table {
        th { font-size: 16px; font-weight: 600; }
      }
    }
    .customer-info {
      .customer-name-section { display: flex; align-items: center; }
      .customer-name { font-size: 16px; font-family: $mulish; font-weight: 700; }
      .customer-address { margin-left: 65px; }
      .customer-address p { color: #3D3935; font-size: 16px; font-weight: 600; }
      .customer-contact-info {margin-left: 65px; }
      .customer-contact-info p { color: #3D3935; font-size: 16px; font-weight: 600; }
      // a { font-size: 13px; }
    }
    
    p { margin: 8px 0; }

    .order-quantity { 
      & { width: 100px; }
      .MuiOutlinedInput-input { text-align: right; }
    }
    .order-price {
      & { width: 100px; }
      .MuiOutlinedInput-input { text-align: right; }
    }
    .order-total {
      & { width: 100px; }
      .MuiOutlinedInput-input { text-align: right; }
    }
  }
  .MuiPaper-root {width: 95%; border-radius: 8px; box-shadow: 0px 2px 10px #0000000F; padding: 10px; margin: 20px; width: calc(100% - 40px); }
  .MuiTableCell-root { font-family: $mulish; font-weight: 600; font-size: 16px; }
  .MuiTableCell-head { font-family: $mulish; font-weight: 700; font-size: 16px; }
  .MuiOutlinedInput-root { font-family: $mulish; font-size: 14px; width: 80%; }
  .MuiOutlinedInput-input { padding: 8px; }
  .MuiSelect-select { padding: 8px; }
  .MuiMenuItem-root { font-family: $mulish; font-size: 14px; }
  .MuiButtonBase-root-MuiMenuItem-root { font-family: $mulish; font-size: 14px; }

  .totals {
    & {  width: calc(100% - 40px); background-color: #FFFFFF; border-radius: 8px; box-shadow: 0px 2px 10px #0000000F; padding: 10px; margin: 0px 20px; }
    .total-row { 
      & { display: flex; justify-content: space-between; font-size: 16px; font-weight: 500; }
      .spacer { flex: 1 1 auto; font-weight: 600; }
      .description { flex: 0 1 100px; text-align: right; padding: 10px 16px; font-weight: 600; }
      .amount { flex: 0 1 100px; text-align: right; padding: 10px 16px; font-weight: 600; }
    }
    .total {  font-weight: 900; }
  }

  .order-btns { 
    & { position: fixed; bottom: 0; background: #efefef; width: 85vw; display: flex; padding: 15px 30px; margin-left: 0; justify-content: space-between; }
    .spacer { flex: 1 1 auto; }
    .confirm-btn { background-color: #eb4d4b; color: #ffffff; font-weight: 700; padding: 5px 10px; margin-left: 20px; }
    .cancel-btn { background-color: #fff; color: #313131; font-family: $mulish; font-size: 13px; }
    .edit-products-btn {  margin: 0 30px 0 30px; background-color: #636951; color: #FFFFFF; border-radius: 4px; }
  }

}


//blog drawer container

.blog-drawer-container {
  & { width: 100vw !important; height: auto; padding: 0; overflow: hidden; background: #F6F7FA; }
  .blog-drawer {
    & { width: 100%; height: 100%; display: grid; grid-template-rows: 176px 1fr 100px; }

    .blog-drawer-header {
      & { background: #E5E5E8; }
      header {
        & { border-bottom: none; margin-top: 20px; }
      }
      .blog-webpage-clone {
        & { width: 100%; background: #FFF; height: 36px; margin-top: 3rem;  }
        .webpage-clone {
          & { position: relative; width: 100%; height: 100%; box-shadow: 0px -5px 10px 0px #aaaaaa40; }
          .webpage-link {
            & { position: relative; width: 100%; height: 100%;  display: flex; align-items: center; z-index: 10;  }
            .link-icon { margin: 0 5px; }
            .link-container {
              & { flex: 1; display: flex; align-items: center;background: #E5E5E8; height: 80%; margin: 0 1rem; border-radius: 20px; }
              span {
                & { margin-left: 1rem; font-size: 14px; font-weight: 700;  }
              }
              input { 
                & { flex: 1; font-size: 14px; border: none; background: transparent; padding-top: 3px; margin-right: 2rem; margin-left: -1px; font-weight: 700; font-family: "Nunito", sans-serif; }
                &:focus { outline: none; }
              }
            }
          }
          .title-tab {
            & { z-index: 9; position: absolute; display: flex; align-items: center; gap: 10px; height: 35px; width: 300px; padding: 0 10px; background-color: white; top: -33px; left: 16px; border-top-right-radius: 15px; border-top-left-radius: 15px; box-shadow: 0px -5px 10px 0px #aaaaaa40; }
            img { height: 20px; width: 20px; }
            span { flex: 1; white-space: pre; overflow: hidden; text-overflow: ellipsis; font-size: 14px; font-weight: 600; }
          }
          .action-buttons-div {
            & { position: absolute; top: -55px; align-items: center; right: 1rem;  display: flex; gap: 10px;  padding-bottom: 10px;}
              .save-button{ background-color: #1c2238; font-size: 16px !important; height: 30px; }
              .cancel-button{ background-color: transparent; height: 30px; color: #1c2238; border: 1px solid #1c2238; font-size: 16px !important; font-weight: 600; font-family: "Nunito";}
          }
        }

        .meta-tags-container {
          & { position: absolute; width: 100%;height: 140px; background: #FFF; z-index: 100; box-shadow: 0px 15px 15px 0px #aaaaaa80; padding: 0 10% 0 10%; }
        }
      }
      .editor-toolbar-container {
        & { position: relative; box-shadow: 0px 5px 15px 0px #aaaaaa30; }
        .editor-toolbar-control {
          & { z-index: 100; background: #ffffff; position: absolute; inset: auto auto -25px auto; height: 30px; width: 30px; display: flex; align-items: center; justify-content: center; cursor: pointer; box-shadow: 0px 5px 15px 0px #aaaaaa30; border-radius: 0 0 5px 5px; }
        }
        .tools-div { background: #ffffff; display: flex; height: 40px; flex-wrap: wrap; justify-content: center; align-items: center; border: 0; } 
          .tools-button { display: flex; flex: 1; align-items: center; justify-content: center; } .ql-formats { display: flex; } 
          .action-buttons-div { display: flex; gap: 10px; }
          .save-button { background-color: #1c2238; font-size: 16px !important; height: 30px; }
          .cancel-button { background-color: transparent; height: 30px; color: #1c2238; border: 1px solid #1c2238; font-size: 16px !important; font-weight: 600; font-family: "Nunito"; }
    }
  }

    
     // .tags-container .topic-row .single-tag .add-author { height: 18px; width: 18px; cursor: pointer; } 
     // .tags-container .topic-row .single-tag:has(.add-author) { background: #FFF; border: 1px solid #111; color: #111; cursor: pointer; }
     //  .tags-container .tags-row { width: 41%; max-width: 41%; display: flex; align-items: center; flex-wrap: wrap; gap: 5px; min-height: 100px; padding: 0 5px 0 5px; } 
     // .tags-bar-control {
     //   & { z-index: 100; background: #ffffff; position: absolute; inset: auto auto 95px 0; height: 30px; width: 30px; display: flex; align-items: center; justify-content: center; cursor: pointer; box-shadow: 0px -5px 15px 0px #aaaaaa30; border-radius: 5px 5px 0 0; }
     // }
     .tags-container { 
      & { position: relative; margin: 0 auto; display: flex; background: #ffffff; align-items: center; gap: 10px; width: 100%; border: 0px; height: auto; } 
      .topic-row { 
        & { width: 35%; max-width: 35%; display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: nowrap; gap: 5px; height: auto; margin-left: 40px; flex-direction: column; border-right: 2px solid #E9EAEE; overflow-x: auto; white-space: nowrap; scrollbar-width: thin; scroll-padding: 10px; } 
        .single-tag { 
          & { position: relative; font-size: 14px; color: #9B9B9B; width: auto; height: 32px; background: #E9EAEE 0% 0% no-repeat padding-box; border-radius: 4px; padding: 8px; display: flex; align-items: center; justify-content: center; gap: 5px; opacity: 1; margin: 5px; cursor: pointer; } 
        } 
        .topic-collection { 
          & { display: flex; width: auto; flex-wrap: nowrap; overflow-x: auto; scroll-padding: 10px; } 
          .single-tag { 
            & { flex-shrink: 0; position: relative; font-size: 14px; color: #9B9B9B; width: auto; height: 32px; background: #E9EAEE 0% 0% no-repeat padding-box; border-radius: 4px; padding: 8px; display: flex; align-items: center; justify-content: center; gap: 5px; opacity: 1; margin: 5px; cursor: pointer; } 
          } 
        } 
      }
      .tags-row { 
        & { display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; width: 35%; max-width: 35%; flex-wrap: nowrap; margin-left: 40px; overflow-x: auto; scrollbar-width: thin; scroll-padding: 10px; } 
        .single-tag { 
          & { position: relative; font-size: 14px; color: #9B9B9B; width: auto; height: 32px; background: #E9EAEE 0% 0% no-repeat padding-box; border-radius: 4px; padding: 8px; display: flex; align-items: center; justify-content: center; gap: 5px; opacity: 1; margin: 5px; cursor: pointer; flex-shrink: 0; } 
        } 
        .tags-collection { 
          & { display: flex; width: auto; flex-wrap: nowrap; overflow-x: auto; scroll-padding: 10px; } 
          .single-tag { 
            & { position: relative; font-size: 14px; color: #9B9B9B; width: auto; height: 32px; background: #E9EAEE 0% 0% no-repeat padding-box; border-radius: 4px; padding: 8px; display: flex; align-items: center; justify-content: center; gap: 5px; opacity: 1; margin: 5px; cursor: pointer; flex-shrink: 0; } 
          } 
        } 
      } 
    }
    
    
      //  .tags-container .tags-row .single-tag .remove-author { height: 16px; width: 16px; cursor: pointer; } 
      //  .tags-container .tags-row .single-tag .add-author { height: 18px; width: 18px; cursor: pointer; } 
      //  .tags-container .tags-row .single-tag:has(.add-author) { background: #FFF; border: 1px solid #111; color: #111; cursor: pointer; } 
    .status-container { 
      & { border-left: 2px solid #E9EAEE; width: 30%; display: flex; align-items: flex-end; justify-content: center; gap: 10px; height: 100px; flex-direction: column; padding-right: 40px; } 
      .status { 
        & { display: flex; align-items: center; } 
      }
      .status p { 
        & { font: normal normal normal 14px/18px Nunito; margin-right: 5px; } 
      }
      .status-date { 
        & { font: normal normal bold 14px/18px Nunito; } 
      }
      .status-button { 
        & { width: auto; height: 32px; background: #E9EAEE 0% 0% no-repeat padding-box; border-radius: 24px; opacity: 1; padding: 7px 16px; border-radius: 24px; color: #9B9B9B; background-color: #E9EAEE; cursor: pointer; font: normal normal 600 14px/18px Nunito; } 
      }
      .published { 
        & { background-color: #1c2238; color: #FFF; font-size: 14px; } 
      }
    }
  }


//create-order-drawer

.create-order-drawer{ background-color: red; }


// reatilers details drawer

.retail-drawer {
  .head { font-size: 24px; color: #3D3935; font-weight: bold; margin: 20px 0; display: flex; align-items: center; gap: 20px; }


 .details-section { width: 100%; height: 100%; } 
  .details-section .buttons-div { display: flex; gap: 20px; margin: 20px 0; }
  .details-section .buttons-div button { border: none; border-radius: 5px; padding: 8px 26px; font-size: 20px; color: #3D3935; } 
  .details-section .statistics .graph { } 
  .details-section .statistics .graph-details { display: flex; justify-content: center; align-items: center; gap: 24px; } 
  .details-section .statistics .graph-details .card { background-color: white; align-items: center; display: flex; flex-direction: column; height: auto; width: 208px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; border-radius: 10px; padding: 8px; margin: 8px; } 
  .details-section .statistics .graph-details .card .title { color: #AA7B59; font-size: 34px; font-weight: bold; margin: none !important; padding: none !important; }
   .details-section .statistics .graph-details p { color: #3D3935; font-size: 20px; margin: none; }
  .details-section .header-location { display: flex; justify-content: end; gap: 20px; }
   .details-section .details .card { display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 1rem; }
    .details-section .details .card .row { font-weight: bold; display: flex; width: 100%; justify-content: space-between; align-items: center; }
     .details-section .details .card .row div { display: flex; align-items: center; gap: 8px; } 
     .details-section .details .card .row-end { display: flex; align-items: center; justify-content: end; width: 100%; }
      .details-section .images { display: flex; margin: 1rem; flex-wrap: wrap; gap: 10px; } 
      .details-section .images img { height: 242px; width: 233px; } 
      .details-section .location .card-div { width: 100%; display: flex; align-items: center; flex-wrap: wrap; height: 100%; } 
      .details-section .location .card-div .card { min-width: 310px; min-height: 142px; padding: 20px; margin: 10px; font-size: 16px; color: #3D3935; } 
      .details-section .location .card-div .card-add { background-color: #EEEEEE; font-size: 24px; color: #9B9B9B; display: flex; align-items: center; justify-content: center; min-width: 310px; min-height: 142px; padding: 20px; margin: 10px; box-shadow: none !important; border-radius: 8px; }


  & { width: 80vw; padding-left: 20px; }
  header { width: calc(100% - 60px); padding: 0 30px; background: #efefef; padding-left: 30px; display: flex; justify-content: space-between; align-items: center; }

  .MuiTabs-flexContainer { background: #efefef; }

  section { background: #efefef; padding: 30px; min-height: calc(100vh - 130px); width: calc(100% - 60px); overflow-y: scroll; }

  .double-column-form { 
    & { display: flex; align-items: center; justify-content: flex-end;}
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

}


// product details drawer

.product-drawer {
  .head { font-size: 24px; color: #3D3935; font-weight: bold; margin: 20px 0; display: flex; align-items: center; gap: 20px; } 
  .head h1 { color: #3D3935; font-size: 24px; }

  .details-section{ & { width: 100%; height: 100%; }

  .buttons-div { display: flex; gap: 20px; margin: 20px 0; } 
  .buttons-div button { border: none; border-radius: 5px; padding: 8px 26px; font-size: 20px; color: #3D3935; }


    .statistics{
      .graph{ }
      h1 {margin: 5px auto; text-align: center; padding-top: 20px;}

      .graph-details{ & { display: flex; justify-content: center; align-items: center; gap: 24px; }
      .card { background-color: white; align-items: center; display: flex; flex-direction: column; height: auto; width: 208px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; border-radius: 10px; padding: 8px; margin: 8px; }
      .card .title { color: #AA7B59; font-size: 34px; font-weight: bold; margin: none !important; padding: none !important; }
      .card p { color: #3D3935; font-size: 20px; margin: none; }
      .top-bar { fill: red;}
      }
    }
    
    .details .card { display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 1rem; } 
    .details .card .row { font-weight: bold; display: flex; width: 100%; justify-content: space-between; align-items: center; } 
    .details .card .row div { display: flex; align-items: center; gap: 8px; }
    .details .card .row-end { display: flex; align-items: center; justify-content: end; width: 100%; }


    //inventory
    .margin {
      & { display: flex; text-align: center; justify-content: center; border: none; margin: 30px auto 0px; }
      .inventory-card { & { box-shadow: #0000000A 0px 2px 10px; height: 188px; width: 428px; border-radius: 10px; } 
      h1 { color: #3D3935; font-size: 48px; margin-bottom: 5px; } 
      .inventory-status { background-color: #636951; border-radius: 24px; color: #FFFFFF; font-size: 14px; line-height: 18px; } }    
    }

    .details { & { display: flex; flex-direction: column; align-items: center; }
     .details .recipe-heading { color: #3D3935; font-size: 16px; line-height: 20px; align-items: flex-start; width: 502px; margin: 10px; padding-left: 5px; } 
     .details .inventory-menu { display: flex; } 
     .details .recipe-text { border: 1px solid #C6C6C6; border-radius: 2px; width: 502px; height: 198px; overflow: scroll; }
      .details .MuiInput-underline::after { border-bottom: none; } 
      .details .MuiInput-underline::before { border-bottom: none; } 
      .details .MuiInputLabel-animated { padding-left: 10px; font-size: 18px; color: #9B9B9B; }
       .details .MuiInputLabel-shrink { color: #9B9B9B; padding-top: 10px; }
        .details .MuiInput-input { padding: 10px; font-family: inherit; } 
        .details .recipe-button { border-radius: 4px; background-color: #AA7B59; color: #FFFFFF; width: 502px; height: 40px; margin-top: 30px; margin-bottom: 10px; } }

        .product-details { & { margin: 10px auto 5px; padding-top: 20px; box-shadow: #0000000A 0px 2px 10px; border-radius: 10px; } 
        .product-details .details-card { width: 550px; height: 454px; padding: 5px 15px; } 
        .product-details .product-title { padding-top: 20px; font-size: 20px; color: #3D3935; } 
        .product-details .product-description { color: #3C3935; font-weight: 700; } }


        .images-container { & { margin: 10px auto 5px; padding-top: 20px; box-shadow: none; border-radius: 10px; } 
        .images-container .product-image-card { border-radius: 4px; width: 400px; height: 400px; border: none; margin: 0 auto 20px; } 
        .images-container .product-image { height: 400px; width: 400px; }
         .images-container .product-image-gallery { margin: 40px auto 10px; box-shadow: none; } 
         .images-container .gallery { width: max-content; box-shadow: none; } }

    
         .reviews { & { width: 654px; height: 622px; box-shadow: 0px 2px 10px #0000000A; background-color: #FFFFFF; margin: 30px auto 10px; } 
         .reviews .review { margin-top: 5px; margin-bottom: 25px; } 
         .reviews .reviews-heading { color: #3D3935; font-size: 20px; padding: 15px 15px; } 
         .reviews .review-container { font-family: inherit; padding-left: 25px; } 
         .reviews .reviewer-data { display: flex; justify-content: space-between; } 
         .reviews .review-name-ratings { display: flex; flex-direction: column; margin-left: 10px; }
          .reviews .reviewer-column { display: flex; } 
          .reviews .MuiRating-root { font-size: 18px; } 
          .reviews .rating { display: flex; }
           .reviews .rating-score { font-size: 14px; padding-left: 5px; }
            .reviews .name { color: #3D3935; font-size: 14px; line-height: 18px; }
             .reviews .rating-score { font-size: 14px; color: #3D3935; }
              .reviews .review-date { font-size: 14px; color: #989898; line-height: 18px; padding-right: 40px; align-self: end; padding-bottom: 12px; }
               .reviews .review-text { color: #3D3935; font-size: 16px; line-height: 20px; margin-left: 50px; margin-top: 5px; } }
 

   


    
  }

  & { width: 72vw; padding-left: 20px; color: #FCFAF9; box-shadow: 0px 2px 10px #00000014; }
  header { width: calc(100% - 60px); padding: 0 30px; background: #efefef; padding-left: 30px; display: flex; justify-content: space-between; align-items: center; }

  .MuiTabs-flexContainer { background: #efefef; }

  section { background: #efefef; padding: 30px; min-height: calc(100vh - 130px); width: calc(100% - 60px); overflow-y: scroll; }

  .double-column-form { 
    & { display: flex; align-items: center; }
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

  

}

//product image-drwer
.product-image-drawer {
  & {position: relative; box-sizing: border-box; padding: 0.5rem 0 0 0.5rem;}
  & {max-height: 100vh;}
  .header {font-size: 24px; font-weight: bold; padding: 5px 10px; border-radius: 3px; background: #fefefe; border: 1px solid #777; width: fit-content; margin-bottom: 20px;}
  .card-container{
    .card {
      & {width: 350px; margin-right: 40px;}
      .img {
        &{ max-height: 400px; width: fit-content; margin-bottom: 25px;}
        img {height: 400px; width: 320px; object-fit: cover; border: 1px solid #777; border-radius: 3px;}
      }
    }
  }
  .card:first-of-type {
    & {width: 400px; margin-right: 40px;}
    .img {
      &{ max-height: 500px; width: fit-content; margin-bottom: 25px;}
      img {height: 500px; width: 400px; object-fit: cover; border: 1px solid #777; border-radius: 3px;}
    }
  }
  .MuiButton-root {background: #fefefe;}
  .btn {margin: 0 10px;}
  .center {text-align: center; align-items: center;}
  .space-between {justify-content: space-between;}
  .space-evenly {justify-content: space-evenly;}
  .justify-center {justify-content: center;}
  .flex {display: flex;}
}


.product-image-drawer-new {
  & { color: #3D3935; }

  .product-display-img {
    & { width: 100%; padding: 1rem; margin-bottom: 1rem; }
    .product-display-header { 
      & { font-size: 20px; font-weight: 700; margin-bottom: 10px; }
    }
    .product-display-content {
      & { display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 1rem; }
      .display-img {
        & { display: flex; align-items: center; justify-content: center; height: 500px; width: 350px; background: #e3e3e3; }
        &>img {
          & { height: 100%; width: 100%; object-fit: cover; }
        }
      }
    }
  }

  .product-additional-img {
    & { width: 100%; padding: 1rem; margin-bottom: 1rem; }
    .product-additional-header { 
      & { font-size: 20px; font-weight: 700; margin-bottom: 10px; }
    }
    .product-additional-content {
      & { width: 100%; }
      .additional-images {
        & { width: 100%; display: flex; align-items: center; align-content: flex-start; justify-content: flex-start; flex-wrap: wrap; gap: 1.5rem; }
        .additional-img {
          & { position: relative; width: 227px; height: 304px; }
          &>img {
            & { height: 100%; width: 100%; }
          }
          .edit-icon {
            & { display: none; cursor: pointer; align-items: center; justify-content: center; padding: 5px; background: #ffffff; border-radius: 4px; position: absolute; right: 10px; top: 10px; }
          }
          .delete-icon {
            & { display: none; cursor: pointer; align-items: center; justify-content: center; padding: 5px; background: #FFF; border-radius: 4px; position: absolute; right: 55px; top: 10px; }
          }

          &:hover {
            .edit-icon {
              & { display: flex; }
            }
            .delete-icon {
              & { display: flex; }
            }
          }
        }
        .add-additional-img {
          & { cursor: pointer; border-radius: 5px; display: flex; align-items: center; justify-content: center; flex-direction: column; width: 227px; height: 304px; background: #e3e3e3; }
          .add-icon { height: 70px; width: 70px; }
        }
      }
    }
  }
}

//Order Address Modal

.edit-tags-container {
  & { max-height: 600px; width: auto; min-width: 550px; padding: 20px; }
  section {
    & { display: flex; flex-direction: column; height: 90%; }

    .address-card-container{ display: flex; flex-direction: column; gap: 10px;
      .card{ backgroundColor: #FCFAF9; padding: 10px 0px; border-radius: 10px;  width: 100%;
        .address-card{
        width: 100%; display: flex; gap: 20px; align-items: center;
          p{ color: #3D3935; font-size: 16px; } 
          }
        .edit-icon{
          display: flex; width: 100%;align-items: center;justify-content: flex-end; gap: 5px; margin-bottom: 10px; margin-right: 10px;  }
      }
      .add-address { background-color: #EEEEEE; border-radius: 10px; display: flex; font-size: 18px; align-items: center; justify-content: center; color: #9B9B9B; height: 43px;padding: 10px; margin-bottom: 10px; cursor: pointer; }
    }
    .address-textfield-container{ display: flex; flex-direction: column; width: 100%; color: #3D3935; font-weight: 600;
      input{ padding:10px; border: 1px solid #C6C6C6; border-radius: 6px;
      }
      .single-textfield{ display: flex; flex-direction: column; gap: 8px;width: 100%; 
        .address{ margin-bottom: 20px; }
      }
      .double-textfield{ display: flex; gap: 20px; width: 100%; padding-bottom: 20px;}
    }

    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; }
      .close-btn { color: #1a66ff; background: #fff; border: 2px solid #1a66ff; }
    }

    .search-tag-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 10px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .tags-menu {
      & { width: 100%; min-height: 50px; max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 40px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
        }
        .add-new-tag {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .tag-text { color: #1a66ff; font-weight: 600; }
        }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-wrap: wrap; }
      .author-tag {
        & { height: 24px; background: #e3e8f0; padding: 10px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;  }
        .remove-author { height: 16px; width: 16px; cursor: pointer; }
      }
    }
  }
}

.account-details {&{ max-height: 600px; width: auto; min-width: 550px; }
  .detail {display: flex; flex-direction: column; margin-top: 15px; }
  .detail label { font-size: 16px; font-weight: 700; margin: 10px 5px; }
  .detail input { border: 1px solid #C6C6C6; border-radius: 4px; height: 40px; align-items: center; }
  .detail input::placeholder {padding-left: 5px; }
  .save-changes-btn {& { margin-top: 25px; display: flex; justify-content: space-between; }
    .MuiButton-root { height: 40px; width: 239px; }

}
}


//Manage Compnay Drawer

.contact-container{ 
  &{ display: flex; padding: 2rem 2rem; flex-direction: column; gap: 1rem;}

  .image-container{
    &{ height: 100px; width: 100px; border-radius: 100%;  overflow: hidden;}
  }
}

.button-container{
  &{ display: flex; justify-content: space-between; }
}
.company-item{&{background-color: red !important;}}


// Commom Class

.flex-drawer { display: flex; }

.column-full-width{ width: 100%; justify-content: space-between;}

.full-width-column{width: 100% !important;}

.tabs-card[aria-selected="true"]{ color: black; }

.tabs-card[aria-selected="false"]{ color: gray; }
.company-logo-img{ height: 2rem; width: 2rem; border-radius: 100%; margin-right: .5rem;}
.drawer-option-title{width: 100% !important; padding: .5rem 1rem;  display: flex; justify-content: space-between;}

.sticky-top{ position: sticky; top: 0; z-index: 100; background-color: white; font-weight: bold;}

.add-category-content {

  h3 { font-weight: 400; }
  & { padding: 30px; }
  .maincatgory { margin: -10px 0; }
  .warning { 
    & { font-size: 16px; font-weight: 300; background-color: wheat; padding: 10px; color: rgb(137, 115, 75); margin-top: 10px; }
    span { font-weight: 500; }
  }

}

.add-category-content .icon-div{
  & {display: flex; flex-direction: column; gap: 1rem;}
  span{font-weight: bold;}
}
.button-div{ display: flex; justify-content: space-between; align-items: center; }
}