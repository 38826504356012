.topics-page {
  & { height: 100%; width: 100%; }

  .template-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; border-bottom: 1px solid #bbb; }
  }
  .template-section {
    & { height: calc(100% - 60px); padding: 1rem; overflow-y: scroll; }

    //main grid/table
    .topics-table {
      & { width: 100%; height: 100%; overflow-y: scroll; }

    }
  }
}