.upload-image-modal-container {
  & { height: 80vh; width: 60vw; display: flex; flex-direction: column; align-items: center; justify-content: space-between; }

  .title { 
    & { height: 50px; width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 0 15px; }
    h2 { margin: 0; color: #1c2238; }
    .MuiSvgIcon-root { color: #1c2238; cursor: pointer;}
  }

  .crop {
    & {  height: calc(100% - 130px); padding: 10px 0; position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    .d-n-d { 
      & { height: 80%; width: 90%; border: 2px dashed #929292; border-radius: 10px; display: flex; align-items: center; justify-content: center;flex-direction: column;gap: 2rem;}
    }
  }

  .crop-btns {
    & { position: relative; height: 80px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .tips {
      & { position: absolute; bottom: -10px; left: 0px; display: flex; align-items: center; justify-content: flex-start; }
      &>span {
        & { color: #1c2238; font-size: 14px; }
      }
    }
  }
}


.select-item-modal {
  & { width: 600px; max-height: 600px; padding: 20px; }
  section {
    & { display: flex; flex-direction: column; height: 90%; }

    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; justify-content: flex-end;}
      .close-btn { color: #1c2238; background: #fff; border: 2px solid #1c2238; }
    }

    .search-item-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 16px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .items-menu {
      & { width: 100%;  max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 40px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
        }
        .add-new-item {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .item-text { color: #1c2238; font-weight: 600; }
        }
      }
    }
    .item-list {
      & { min-height: 1rem; display: flex; flex-wrap: wrap; padding: 1rem 0; gap: 5px; }
      .item {
        & { padding: 3px 5px; border-radius: 3px; display: flex; align-items: center; justify-content: center; gap: 5px; background: #e7e7e7; }
        .remove-item{
          cursor: pointer;
        }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-wrap: wrap; gap: 5px; }
    }
  }
}

.publish-blog-modal {
  & { width: 500px; max-height: 600px; padding: 20px; }
  .heading { 
    & { display: flex; align-items: center; justify-content: space-between; margin-bottom: 1rem; }
    .close { cursor: pointer; }
  }
  .footer {
    & { margin-top: 1rem; display: flex; align-items: center; gap: 1rem;justify-content: flex-end; }
  }
}

.delete-modal {
  & {width: 360px;height :120px ;padding: 20px;display: flex;align-items: flex-start;flex-direction: column;justify-content: center; }
  .heading { 
    & { display: flex; align-items: center; justify-content: space-between; margin-bottom: 1rem; }
    .close { cursor: pointer; }
  }
  .footer {
    & { margin-top: 1rem; display: flex; gap: 1rem; width: 100%; justify-content: flex-end;}
  }
}


.create-devotional-container{ 
  &{ min-width: 500px; padding: 0;}
  .devotional-header{ 
    &{ background-color: #1c2238; justify-content: center; display: flex; align-items: center;}

    h1{ color: #fff; background-color: #1c2238; font-size: 1.5em; margin: 0; padding: 20px 0; border-top-right-radius: 7px; border-top-left-radius: 7px;
    }
  }

  .body-container{
    &{
      padding: 1.25rem 1rem;
    }
  }

  .footer-devotional{ 
    &{background: #ecf0f1; padding: 10px;    display: flex; -webkit-box-pack: end; justify-content: flex-end; }

    .button{
      color: white !important;
    }
   }
}

.agent-modal{ display: flex;  flex-direction: column; gap: .5rem; }


.create-coupon-modal{

  .coupon-modal-header{
    &{display: flex; justify-content: end; align-items: start; padding: 1rem; }
  }

  .coupon-body-container{
    &{display: flex; justify-content: center; flex-direction: column; align-items: center; gap: 1rem;}
    img{height: 60px; border-radius: 100%;}
  }
}

.add-location-modal{

  &{position: relative; width: 100%; background-color: white; min-height: 500px;}

  .close {
    &{position: absolute; top: 0; right: 0; margin: 1rem; cursor: pointer; z-index: 999;}
  }

  .map-body-container{
    &{display: flex; border-radius: 10px; justify-content: center; flex-direction: column; align-items: center;}

    .map{ 
      &{
        height: 600px; min-width: 90vw; background-color: #aaa; position: relative;
      }
      .location-marker{
        &{z-index: 10; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 30px; height: 30px; background-color: #1c2238; border-radius: 100%; }
      
      }
     }
    .textfield-container{
      &{display: flex;  gap: 1rem; width: 100%; background-color: white; padding:  1rem; align-items: center;}
    }
  }
}




.loading{
  display: flex;
  justify-content: center;
  align-items: center;
}

//Upload Company Logo

.css-16y5apd {
  top: 50% !important;
  align-items: center;
}
.data-loading-circle{ text-align: center; width: 100%;}

.upload-logo-body{
  &{display: flex;justify-content: center;align-items: center; flex-direction: column;}
  .heading{font-family: proxima-nova, sans-serif;font-size: 18px; font-weight: 800; margin-bottom: 20px;}
}

// Coupons Modals

.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  text-transform: unset !important;
}
.filter-by-category-body{
  align-items: left;overflow-y: auto; height: 65vh;padding: 20px;
}

.disabled {
  pointer-events: none; /* Prevent interaction */
  opacity: 0.5;        /* Reduce visibility to indicate it's disabled */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.filter-field{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  width: max-content;
}