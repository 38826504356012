

.coupons-container {
  & { padding: 1rem; height: 100%; width: 100%; }

  .header-section {
    &{background-color: white; border-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; display: flex; width: 100%; align-items: center; justify-content: space-between; padding: 1rem 2rem;}
    .title{  margin: 10px 0;  font-size: 1.5em; font-weight: 800; text-transform: uppercase;  color: #535c68; font-family: 'proxima-nova', sans-serif; }
    .header-items-div{ display: flex; align-items: center; gap: .5rem;}
    .filter-button { font-weight: 800; font-family: 'proxima-nova', sans-serif; font-size: 14px; text-transform: uppercase; white-space: nowrap;}
  }

  .status-active {
    & { position: relative; padding: .25rem 1.25rem; display: flex; width: fit-content; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #dff0de; color: #329c3a; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #329c3a; }
  }
  .status-invite {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #fff2d7; color: #f9b104; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #f9b104; }
  }
  .status-pending {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center;width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #d4e7ff; color: #0472f9; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #0472f9; }
  }
  .status-suspended {
    & { position: relative;padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
  }

  .coupon-table {
    & { height: calc(100% - 140px); scrollbar-color: #535c68 #f3f4fb; }
    .company-head-cell { font-size: 13px;  color: #9294a3 !important; font-family: 'proxima-nova', sans-serif; text-transform: uppercase !important; font-weight: 800 !important; background-color: #f3f4fb; }
    .company-table-body {
      & { }

      .company-table-row{
        & { } 
        
        .company-table-cell {
          & { border-bottom: none !important;  font-weight: 400 !important; font-family: 'proxima-nova', sans-serif; }
          .companyName {
            & { display: flex; align-items: center; gap: .5rem; }
            img { height: 32px; width: 32px; border-radius: 100%; object-fit: cover;}
          }
          // .icons-container{
          //   display: flex; width: fit-content; gap: 8px;
          //   .button-icon{width: 20px !important; padding: 0; margin: 0;}
          // }

          .coupon-count {
            & { border-radius: 5px; position: relative; font-family: 'proxima-nova', sans-serif; width: fit-content !important; text-align: center; padding: 4px 15px; gap: 16px; padding-bottom: 4px; font-size: 14px; display: flex; align-items: center; }
            // img{ height: 16px; width: 20px;}
            .coupon-count-img { width: 13px; opacity: 0.6; }
            .mapIcon { width: 10px; }
          }
          .coupon-count-more { border: 1px solid #b3b5c6; color: #535c68; }
          .coupon-count-none { 
            & { border: 1px dashed #e3e3e7; color: #e3e3e7; }
            .coupon-count-img { opacity: 0.3; }
          }
        }
      }
    }
    
  }
  .agent-table-pagination {
    & { height: 53px; }
  }
  
}


.company-table-head{ background-color: #f3f4fb; }


.grey-btn{ background: #f1f2f6 !important;  color: #535c68 !important;    margin: 0 !important;  min-width: 64px !important;  line-height: 36px !important;  padding: 0 16px !important;  border-radius: 4px !important; overflow: visible !important;}

.blue-button{ font-family: proxima-nova !important; white-space: nowrap;  color: #fff; background-color: #2b1de8 !important; text-transform: capitalize !important; border-radius: 6px !important; padding: 8px 34px !important; height: 38px !important; }

.hidden{
  // background-color: red !important;
  // display: hidden !important;
  height: 0px !important;
  overflow: hidden !important;
}

