.create-coupon-section-v2 {
  & { display: flex; flex-direction: column; gap: 2rem; padding: 3rem; padding-bottom: 0; }
  .options-container {
    & { display: flex; gap: 2rem; width: 100%; }
    .heading-text-container {
      & { display: flex; flex-direction: column; }
    }
    .active-option {
      & { background-color: #1c2238; color: white; border-radius: 8px; padding: 1rem; cursor: pointer; }
    }
    .inactive-option {
      & { background-color: #f2f3f7; color: #1c2238; border-radius: 8px;  opacity: 0.2; cursor: pointer; }
    }
    .option-sub-container {
      & { display: flex; flex-direction: column; gap: 1rem; width: 100%; align-items: start; }
      .multilple-button-container {
        & { width: 100%; display: grid; grid-template-columns: repeat(2, 1fr); gap: 1rem; }
      }
      .text-container {
        & { display: flex; width: 100%; align-items: center; }
        img { width: 100px; height: 100px; border-radius: 100%; }
      }
      .inactive-option {
        & { opacity: 0.3; background-color: white; cursor: pointer; }
      }
      .coupon-deal-option-container {
        & { display: flex; align-items: center; margin-top: 1rem;  }
        .img-block {
          & { position: relative;   margin-right: 40px; display: flex; }
          img { width: 80px; } 
          .img-block-content {
            & { position: absolute; top: 0; text-align: center; width: 100%; display: -webkit-box; display: flex; -webkit-box-pack: center; justify-content: center; -webkit-box-align: center; align-items: center; -webkit-box-orient: vertical; -webkit-box-direction: normal; flex-direction: column; bottom: 10px;
            }
            .img-deal {
              & { color: #000; font-family: proxima-nova,sans-serif; font-size: 14px; font-weight: 600; line-height: 16px; }
            }
            .img-dealunit {
              & { color: #000; font-family: proxima-nova,sans-serif;  font-size: 10px; font-weight: 600; line-height: 12px; }
            }
          }
        }
        #standard-weight-helper-text{
          & { width: max-content !important; }
        }
      }
    }
  }

  .card-container {
    & { display: flex; flex-direction: column; gap: 1rem; background-color: white; padding: 1rem 2rem; align-items: center; width: 100%; }
    img { width: 100px; height: 100px; border-radius: 100%; }
    .mutiple-text-field-container {
      & { display: flex; align-items: center; gap: 2rem; width: 100%; }
      .textfields-container {
        & {
          display: flex; flex-direction: column; gap: 1rem; width: 100%;
        }
      }
    }

    .single-text-field-container {
      & { display: flex; align-items: center; gap: 2rem; width: 100%; justify-content: space-between; }
      form {
        & { width: 100%; }
      }
    }

    .three-col-container {
      & { display: flex; gap: 1rem; width: 100%; }
      .right-container {
        justify-content: flex-start !important;
        align-items: start !important;
      }
      .single-item {
        & { display: flex; flex-direction: column; gap: .25rem; width: 100%; align-items: center; justify-content: center; text-align: center; }
        &.right-border-item { border-right: 1px solid #d7d5e4; }
    
        .card {
          & { padding: 1rem 2rem;  background-color: #eb5c15; display: flex;  flex-direction: column; text-align: left; }
          &.logo-item { flex-direction: row; gap: 30px; border-radius: 10px; box-shadow: 0 4px 2px -2px #fea67b; }
          img { width: 80px; height: 80px; }
          .white-color { color: #FFFFFF; }
        }
        .big-title {
          & { color: #9294a3; font-size: 44px; font-weight: 700; line-height: 46px; text-align: center;}
        }
    
        .big-detail-title {
          & { font-size: 40px; color: #000; font-weight: 700; text-transform: uppercase; }
        }
    
        .detail-title {
          & { color: #1c2237; font-size: 15px; font-weight: 600; text-align: center; }
        }

        .final-title {
          & {color: #9294a3; font-size: 17px; font-weight: 600;}
        }
    
        .item-center-container {
          & { display: flex; gap: .25rem; width: 100%; align-items: center; justify-content: center; flex-wrap: wrap; }
          .selected-item-card {
            & { color: #59585c; border: 1px solid #cfced6;  border-radius: 6px;  padding: 3px 5px;  margin: 4px; white-space: nowrap; }
          }
        }
      }
    }
  }

  .horizontal-row-card-container {
    & { display: flex; width: 100%; gap: 1rem; }
    .center-card {
      & { display: flex; align-items: center; justify-content: center; }
      span { width: auto; }
      img { height: 130px; width: 130px; }
    }
  }
}