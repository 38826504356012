.create-coupon-container {
  & { padding: 1rem; max-height: 100%; width: 100%; overflow: hidden; overflow-y: auto; }
  
  .choose-company-section {
    & { display: flex; justify-content: space-between; }

    .seperator {
      & { padding: 50px 0; display: -webkit-box; display: flex; -webkit-box-pack: center; justify-content: center; -webkit-box-align: center; align-items: center;  position: relative; -webkit-box-flex: 1; flex: 1 0 auto; }
      .line { position: absolute;top: 50px; bottom: 50px; width: 2px; background: #8c93a5;}
      .text { background: #f2f3f7; z-index: 9; position: relative; padding: 15px 0;  color: #1c2238; font-size: 18px;}
    }

    .add-coupons-right {
      & { display: flex; -webkit-box-align: center; align-items: center; padding: 50px; -webkit-box-flex: 1; flex: 1 0 auto;}
      .add-card {
        & { box-shadow: 0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12); border-radius: 6px; }
        .add-a-company {
          & { background-color: #fff; text-align: center; display: flex; flex-direction: column; align-items: center; padding: 70px 20px; border-radius: 6px; }

          img { width: 135px; }
          .text { color: #a3a3a3; padding: 5px 10px; margin-bottom: 20px; }
        }
      }
    }

    .search-company-card {
      & { display: flex; flex-direction: column; color: black; flex: 1 0 1; padding: 50px 10px; gap: 2rem; }
      .companies-item-div-container {
        & { padding: 0px 50px; display: flex; flex: 1; flex-direction: column; gap: 1rem; overflow: hidden; overflow-y: scroll; }
        .companies-item-div {
          & { display: flex; gap: 1rem; align-items: center; cursor: pointer; }
          span { font-weight: bold; }
        }
      }
      .confirm-div { width: 100%; display: flex; justify-content: center; align-items: center; }
    }

    .selectedCompany-div {
      & { padding: 8px 16px; }
      .selected-company-item {
        & { display: flex; gap:.5rem; align-items: center; background-color: #1c2238; width: fit-content; padding: .5rem; color: white; border-radius: 8px; }
      }
    }

  }
  .footer-buttons {
    & { display: flex; align-items: center; justify-content: space-between; padding: 3rem; }
  }

  .create-coupon-section {
    & { display: flex; flex-direction: column; gap: 2rem; padding: 3rem; padding-bottom: 0; }
    .options-container {
      & { display: flex; gap: 2rem; width: 100%; }
      .heading-text-container {
        & { display: flex; flex-direction: column; }
      }
      .active-option {
        & { background-color: #1c2238; color: white; border-radius: 8px; padding: 1rem; cursor: pointer; }
      }
      .inactive-option {
        & { background-color: #f2f3f7; color: #1c2238; border-radius: 8px;  opacity: 0.2; cursor: pointer; }
      }
      .option-sub-container {
        & { display: flex; flex-direction: column; gap: 1rem; width: 100%; align-items: start; }
        .multilple-button-container {
          & { width: 100%; display: grid; grid-template-columns: repeat(2, 1fr); gap: 1rem; }
        }
        .text-container {
          & { display: flex; width: 100%; align-items: center; }
          img { width: 100px; height: 100px; border-radius: 100%; }
        }
        .inactive-option {
          & { opacity: 0.3; background-color: white; cursor: pointer; }
        }
        .coupon-deal-option-container {
          & { display: flex; align-items: center; margin-top: 1rem;  }
          .img-block {
            & { position: relative;   margin-right: 40px; display: flex; }
            img { width: 80px; } 
            .img-block-content {
              & { position: absolute; top: 0; text-align: center; width: 100%; display: -webkit-box; display: flex; -webkit-box-pack: center; justify-content: center; -webkit-box-align: center; align-items: center; -webkit-box-orient: vertical; -webkit-box-direction: normal; flex-direction: column; bottom: 10px;
              }
              .img-deal {
                & { color: #000; font-family: proxima-nova,sans-serif; font-size: 14px; font-weight: 600; line-height: 16px; }
              }
              .img-dealunit {
                & { color: #000; font-family: proxima-nova,sans-serif;  font-size: 10px; font-weight: 600; line-height: 12px; }
              }
            }
          }
          #standard-weight-helper-text{
            & { width: max-content !important; }
          }
        }
      }
    }

    .card-container {
      & { display: flex; flex-direction: column; gap: 1rem; background-color: white; padding: 1rem 2rem; align-items: center; width: 100%; }
      img { width: 100px; height: 100px; border-radius: 100%; }
      .mutiple-text-field-container {
        & { display: flex; align-items: center; gap: 2rem; width: 100%; }
        .textfields-container {
          & {
            display: flex; flex-direction: column; gap: 1rem; width: 100%;
          }
        }
      }

      .single-text-field-container {
        & { display: flex; align-items: center; gap: 2rem; width: 100%; justify-content: space-between; }
        form {
          & { width: 100%; }
        }
      }

      .three-col-container {
        & { display: flex; gap: 1rem; width: 100%; }
        .right-container {
          justify-content: flex-start !important;
          align-items: start !important;
        }
        .single-item {
          & { display: flex; flex-direction: column; gap: .25rem; width: 100%; align-items: center; justify-content: center; }
      
          .card {
            & { padding: 1rem 2rem;  background-color: white; display: flex;  flex-direction: column; text-align: left; }
          }
          .big-title {
            & { color: #9294a3; font-size: 44px; font-weight: 700; line-height: 46px; text-align: center;}
          }
      
          .big-detail-title {
            & { font-size: 40px; color: #000; font-weight: 700; text-transform: uppercase; }
          }
      
          .detail-title {
            & { color: #1c2237; font-size: 15px; font-weight: 600; }
          }

          .final-title {
            & {color: #9294a3; font-size: 17px; font-weight: 600;}
          }
      
          .item-center-container {
            & { display: flex; gap: .25rem; width: 100%; align-items: center; justify-content: center; }
            .selected-item-card {
              & { color: #59585c;  background: #d7d5e4; border: 1px solid #cfced6;  border-radius: 6px;  padding: 3px 5px;  margin: 4px; }
            }
          }
        }
      }
    }

    .horizontal-row-card-container {
      & { display: flex; width: 100%; gap: 1rem; }
      .center-card {
        & { display: flex; align-items: center; justify-content: center; }
        span { width: auto; }
        img { height: 130px; width: 130px; }
      }
    }
  }

  .vertical-container { 
    & { display: flex;  gap: 2rem; }
  }

  .vertical {
    & { display: flex; flex-direction: column !important; gap: 2rem; }
  }
  .header-stepper-section {
    & { display: flex; flex-direction: column; gap: 2rem; padding: 3rem; padding-bottom: 0; }
    
  }
}

// Common Classes in Coupoun Page
.flex-row-container {
  & { display: flex; justify-content: space-between; align-items: center; width: 100%; }
}

.logo-image {
  & { width: 60px; height: 60px; border-radius: 100%; object-fit: cover; }
}

.title {
  & { font-weight: 600; color: #1c2238; width: 100%; }
}

.subtitle {
  & { font-size: 13px; color: #1c2238; }
}

.full-width {
  width: 100%;
}

.checkbox-container {
  & { display: flex; align-items: center; gap: .25rem; width: 100%; }
}

.checkbox-span {
  & { font-size: 14px; display: flex; align-items: center; gap: 3px; color: #1c2238; }
}

.location-header {
  & { display: flex; justify-content: space-between; align-items: center; gap: 1rem; width: 100%; }
}

.title-subtitle-container {
  & { display: flex; flex-direction: column; gap: .25rem; }
}

.gray-title {
  & { font-size: 0.85em; color: #636e72; line-height: 20px;  }
}

.keyword-list {
  & { display: flex; flex-wrap: wrap; gap: .5rem; }
  .keywordBatch {
    & { background-color: #2b1de8;font-size: 14px;  color: #f2f3f7; display: flex; gap: 1rem; align-items: center; border-radius: 30px; padding: .25rem  .75rem; }
  }
}

.center-item {
  display: flex; flex-direction:  column; align-items: center;
}